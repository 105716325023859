import React from "react"
import {graphql} from "gatsby"
import axios from "axios"
import _ from "underscore"

import SEO from "src/components/Element/seo"
import { Consumer } from "src/utils/contexts"

import PostListHome from "src/components/Domain/Post/post_list_home"
import "./index.css"

export default class Search extends React.Component {

    state = {
        res: [],
        posts: [],
        query: null,
        searching_flg: true
    }

    componentDidMount() {
        this.makeSearch()
    }

    componentDidUpdate() {
        const q = decodeURI(this.props.location.search.slice(1).split("=")[1])
        if(this.state.query !== q) {
            this.setState({query: q})
            this.makeSearch()
        }
    }

    makeSearch = () => {
        // set searching screen
        this.setState({searching_flg: true})

        // search
        axios.get('/search.json').then(res => {
            this.setState({res: res.data})
            this.search(this.state.res)
        })
    }

    // 検索
    search = (data) => {

        const query = decodeURI(this.props.location.search.slice(1).split("=")[1])
        const reg = new RegExp(query, "i")

        const search_res = _.filter(data, (node) => {
            let found_bool = false
            _.values(node).forEach((content) => {
                if(_.isString(content)) {
                    if(content.match(reg) !== null) {
                        found_bool = true
                    }
                }
            })
            return found_bool
        })

        this.setState({posts: search_res})

        // unset searching screen
        this.setState({searching_flg: false})
    }

    render() {
        const siteTitle = this.props.data.site.siteMetadata.title
        const posts_for_show = this.state.posts
        const search_query = decodeURI(this.props.location.search.slice(1).split("=")[1])

        return (
            <Consumer>
                {({store, functions}) => (
                    <>
                        <SEO title={siteTitle}/>
                        <div className="search-searching search-wrapper" style={this.state.searching_flg ? {} : {opacity: 0}}>
                            <div className={"search-header"}>
                                <h1 className={"f-lato"}>Searching For...</h1>
                                <div className={"search-text-cloud f-noto"}>
                                    <span>&ldquo; {search_query} &rdquo;</span>
                                </div>
                            </div>
                        </div>
                        <div className="search-result search-wrapper" style={this.state.searching_flg ? {opacity: 0} : {}}>
                            <div id="home" className="main-content"
                                style={posts_for_show.length > 0 ? null : {display: "none"}}
                            >
                                <div className={"search-header result-for-title"}>
                                    <h1 className={"f-lato"}>Results For</h1>
                                    <div className={"search-text-cloud f-noto"}>
                                        <span>&ldquo; {search_query} &rdquo;</span>
                                    </div>
                                </div>
                                <div className="article-list-wrapper">
                                    <PostListHome posts={posts_for_show} />
                                </div>
                            </div>
                            <div className="main-content no-search-result"
                                style={posts_for_show.length > 0 ? {display: "none"} : null}
                            >
                                <div className={"search-header"}>
                                    <h1 className={"f-lato"}>No Results For</h1>
                                    <div className={"search-text-cloud f-noto"}>
                                        <span>&ldquo; {search_query} &rdquo;</span>
                                    </div>
                                    <p className={"no-result-text"}>関連する記事は見つかりませんでした</p>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </Consumer>
        )
    }
}

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`
